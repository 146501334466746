<template>
    <footer>
        <p style="font-weight: bold; margin-top: 80px; margin-bottom: 10vh;">
          made by <a v-bind:href="`https://github.com/y00913`" target="_blank" style="text-decoration: none;">y00913</a>
        </p>
    </footer>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
</style>