<template>
    <div>
        <h1>에러 페이지</h1>
    </div>
</template>

<script>
export default {}
</script>

<style>
</style>