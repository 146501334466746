<template>
    <header style="margin-top:5vh; margin-bottom: 1vh;">
        <title>every chat</title>
        <link rel="shortcut icon" type="image/x-icon" href="https://cdn-icons-png.flaticon.com/512/5962/5962500.png">
        <h1>{{ title }}</h1>
    </header>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            title: '에브리 챗 :)',
        }
    }
}
</script>

<style></style>
